html {
  /* /* scroll-snap-type: y mandatory; */
  scroll-behavior: smooth;
  background-color: #111132;
}

/* scroll to top button */
.myBtn {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #5f2e90;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.none {
  display: none;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s;
  z-index: 1000;
  transform: translateY(0);
  height: 100px;
  background-color: #0c0c1d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* this can be used, if i want a non stick navbar */
/* 
.navbar:not(.visible) {
  transform: translateY(-100%);
} */

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.navbar li {
  padding: 15px 20px;
  cursor: pointer;
}

.navbar li:hover {
  background-color: #555;
}

.social {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  gap: 20px;
  padding-left: 20px;
  a {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
  }
}

.navbar-social-image {
  width: 30px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Links */
.links {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  a {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
  }
}

/* Hero */
.hero {
  min-height: fit-content;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, #0c0c1d, #111132);
}

.hero-navbar-placeholder {
  height: 100px;
  overflow: hidden;
  position: relative;
  background-color: #0c0c1d;
}

/* titles & texts */
.textContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 20px;
  text-decoration: none;
  color: #fff;
}

.h1 {
  font-size: 40;
  color: #5f2e90;
}

.slidingTextContainer {
  font-size: 300px;
  white-space: nowrap;
  color: #ffffff09;
  width: 300%;
  font-weight: bold;
}

/* Screens */
.screen {
  padding-top: 100px;
  min-height: fit-content;
  overflow: hidden;
  position: relative;
  background-color: #111132;
}

.screen-wrapper {
  max-width: 1200px;
  height: 100%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

/* Contact */
.contact {
  padding-top: 100px;
  min-height: calc(fit-content - 150px);
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, #111132, #0c0c1d);
}

.contact-social-image {
  width: 40px;
  height: auto;
  margin-top: 10px;
  margin-right: 20px;
}

/* Footer */
.footerContainer {
  background-color: #0c0c1d;
  height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footerLinks {
  justify-content: center;
  gap: 30px;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
}

.footerCopyright {
  justify-content: center;
  gap: 30px;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
}

/* Logobar */
.logobarContainer {
  width: 100%;
  background-color: #111132;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.logo {
  height: 75px;
  width: 75px;
}

/* Projects */
.projectSliderContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 1%;
}

.projects {
  height: 100%;
  min-height: 500px;
  background-color: #58595b;
  border-radius: 20px;
  text-align: center;
  padding-inline: 5%;
  align-content: flex-start;
  padding-bottom: 2%;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.projectsMargin {
  margin-block: 1%;
  width: 100%;
}

/* Skills */
.skillsSliderContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 70%;
  flex-direction: row;
}

.skills-container {
  height: 100%;
  min-height: 500px;
  background-color: #58595b;
  border-radius: 20px;
  text-align: center;
  padding-inline: 0%;
  align-content: baseline;
  width: 35%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.backend-skills {
  height: 100%;
  min-height: 500px;
  background-color: #58595b;
  border-radius: 20px;
  text-align: center;
  padding-inline: 0%;
  align-content: baseline;
  width: 35%;
  margin-inline: 3%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* About */

.about-info-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
  margin-top: 2%;
  padding-bottom: 1%;
}

.about-info-card {
  height: 170px;
  text-align: center;
  padding-inline: 0%;
  align-content: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #58595b;
  width: 23.5%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-direction: column;
}

.about-info-card-title {
  width: 100%;
  height: 35%;
  background-color: #2d2b2d;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.about-info-card-content {
  width: 100%;
  height: 75%;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* Education */
.education-cards-container {
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 1%;
  width: 100%;
}

.education-card {
  height: 20%;
  min-height: 160px;
  background-color: #58595b;
  border-radius: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 97%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-left: 3%;
  text-decoration: none;
  color: #fff;
}

.education-card-spacer {
  margin-bottom: 3.5%;
  width: 100%;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1320px) {
  .screen-wrapper {
    height: 100%;
    margin: 5%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile */
@media only screen and (max-width: 1050px) {
  .slidingTextContainer {
    font-size: 200px;
    white-space: nowrap;
    color: #ffffff09;
    width: 300%;
    font-weight: bold;
  }

  .screen {
    padding-top: 0;
    min-height: fit-content;
    overflow: hidden;
    position: relative;
    background-color: #111132;
  }

  .contact {
    padding-top: 0;
    min-height: calc(fit-content - 150px);
    overflow: hidden;
    position: relative;
    background: linear-gradient(180deg, #111132, #0c0c1d);
  }

  .screen-wrapper {
    height: 100%;
    margin: 5%;
    margin-top: 0;
    margin-bottom: 5%;
  }

  /* Navbar */
  .navbar {
    display: none;
  }
  .social {
    display: none;
  }
  .logobarContainer {
    display: none;
  }

  /* Hero */
  .hero {
    min-height: fit-content;
    overflow: hidden;
    position: relative;
    background: linear-gradient(180deg, #0c0c1d, #111132);
  }

  .hero-navbar-placeholder {
    height: 100px;
    overflow: hidden;
    position: relative;
    background-color: #0c0c1d;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding-right: 25px;
  }

  .menu-icon {
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #0c0c1d;
    color: #fff;
  }

  /* Skills */
  .skillsSliderContainer {
    justify-content: start;
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 0px;
  }

  .skills-container {
    min-height: fit-content;
    background-color: #58595b;
    border-radius: 20px;
    text-align: center;
    align-content: baseline;
    width: 100%;
    padding-bottom: 7%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .backend-skills {
    min-height: fit-content;
    background-color: #58595b;
    border-radius: 20px;
    text-align: center;
    align-content: baseline;
    width: 100%;
    margin-block: 3%;
    padding-bottom: 7%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  /* About */
  .about-info-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    margin-top: 2%;
  }

  .about-info-card {
    height: 110px;
    text-align: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #58595b;
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
  }

  .about-info-card-spacer {
    margin-bottom: 10%;
    width: 100%;
  }

  .about-info-card-title {
    width: 100%;
    height: 35%;
    background-color: #2d2b2d;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .about-info-card-content {
    width: 100%;
    height: 75%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  /* Footer */
  .footerLinks {
    display: none;
  }

  /* sidebar*/
  .sidebar {
    width: 250px;
    justify-content: flex-start;
    margin-top: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 50px;
    font-size: 30px;
  }

  .sidebar-link {
    text-decoration: none;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    color: #111132;
  }

  .sidebar-social-container {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 20px;
  }

  .sidebar-image {
    width: 50px;
    height: auto;
    background-color: #111132;
    border-radius: 10px;
    padding: 10px;
  }
}
